import { useEffect, useState } from "react";

const HandleKeyboard = ({ shortcuts, executeAction }) => {
    const [keysHeld, setKeysHeld] = useState(new Set()); // Almacena las teclas presionadas

    useEffect(() => {
        const handleKeyDown = (event) => {
            event.preventDefault();

            // Agregar la tecla actual al conjunto
            setKeysHeld((prev) => new Set([...prev, event.key]));

            const modifiers = [];
            if (event.ctrlKey) modifiers.push("Ctrl");
            if (event.shiftKey) modifiers.push("Shift");
            if (event.altKey) modifiers.push("Alt");

            // Obtener la tecla presionada y construir la combinaci?n
            const pressedKey = [...modifiers, event.key].join("+");

            // Buscar si la combinaci?n coincide con un shortcut
            for (const [action, shortcutKey] of Object.entries(shortcuts || {})) {
                if (shortcutKey === pressedKey) {
                    console.log(`Accion detectada: ${action}`);
                    executeAction(action);
                    break;
                }
            }
        };

        const handleKeyUp = (event) => {
            // Eliminar la tecla liberada del conjunto
            setKeysHeld((prev) => {
                const updated = new Set(prev);
                updated.delete(event.key);
                return updated;
            });
        };

        // Registrar eventos
        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("keyup", handleKeyUp);

        return () => {
            // Eliminar eventos
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, [shortcuts, executeAction]);
};

export default HandleKeyboard;
