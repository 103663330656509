import React from 'react';
import {Modal, Button, Row, Col, Container} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotateLeft, faRotateRight} from "@fortawesome/free-solid-svg-icons";
import '../../styles/game.css';

const ChooseServe = ({ showButtons, gameid, handleCloseModal, handleServe, playerOne, playerTwo}) => {
    return (
        <Modal className='w-100'style={{marginTop: '5em'}} show={showButtons} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Choose who serves</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container style={{placeContent: "space-around", display: 'flex', padding: '0 3em'}}>
                    <Button onClick={() => handleServe(1)}>{playerOne}</Button>
                    <div><p>🟡</p></div>
                    <Button onClick={() => handleServe(2)}>{playerTwo}</Button>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChooseServe;
