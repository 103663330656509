import api, {asyncRequest, getHeader} from "../api";

export const getGamesByUser = async(userid, sport) => {
    return await asyncRequest(async () => {
        const formData = new FormData();
        formData.append('userid', userid)

        const response = await api.post(`/${sport}_games_user`,
            formData,
            {
                headers: getHeader('ld+json'),
            }
        );
        return response;
    });

}


export const deleteGame = async(data) => {
    return asyncRequest(async () => {
        const response = await api.delete(`/${data.sport}_games/${data.game_id}`,
            {
                headers: getHeader('ld+json'),
            })

        return response;
    });
}

export const createNewGame = async(formData, sport) => {
    return await asyncRequest(async () => {
        const response = await api.post(`/${sport}/create_game`,
            formData,
            {
                headers: getHeader('ld+json'),
            }
        );
        return response;
    });
}

export const getGameScore = async(gameid, sport) => {
    return await asyncRequest(async () => {
        const response = await api.get(`/${sport}/${gameid}`,
            {headers: {'Content-Type': 'application/ld+json'}}
        );
        return response;
    });
}

export const getPreviousAndNext = async(gameid, sport) => {
    return await asyncRequest(async () => {
        const response = await api.get(`/${sport}/${gameid}/getPreviousAndNext`,
            {
                headers: getHeader('ld+json'),
            });
        return response.data;
    });
}