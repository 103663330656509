import React from 'react';
import { Container, Row, Col, ListGroup, Card } from 'react-bootstrap';
import {Link} from "react-router-dom";


const sports = [{name: 'Padel'},{name: 'Tenis'}];



const Home = () => {
    return (
        <Container>
            <Row className="mt-3">
                <Col md={4}>
                    <Card>
                        <Card.Header>Deportes</Card.Header>
                        <ListGroup variant="flush">
                            {sports.map((sport, index) => (
                                <Link
                                    key={index}
                                    to={`/sports/${sport.name.toLowerCase()}`}
                                    className="nav-link"
                                >
                                    <ListGroup.Item>{sport.name}</ListGroup.Item>
                                </Link>
                            ))}
                        </ListGroup>
                    </Card>
                </Col>
                <Col md={8}>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header>Último Partido</Card.Header>
                                <Card.Body>
                                    {/* Contenido del último partido */}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={12}>
                            <Card>
                                <Card.Header>Estadísticas</Card.Header>
                                <Card.Body>
                                    {/* Contenido de estadísticas */}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Home;
