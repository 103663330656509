import api, {asyncRequest, getHeader} from "../api";

export const sendGameScore = async (gameid, score, sport) => {
    return await asyncRequest(async () => {
        const response = await api.patch(`/${sport}_games/`+gameid,score,
            {
                headers: getHeader('merge-patch+json'),
            }
        );

        return response.data['hydra:member'];
    });
}

export const sendGameWinner = async (gameid, score, sport) => {
    return await asyncRequest(async () => {
        const formData = new FormData();
        formData.append('gameid', gameid);
        formData.append('winner', score.winner);

        const response = await api.post(`/${sport}/set_winner`,formData,
            {
                headers: getHeader('merge-patch+json'),

            }
        );

        return response.data['hydra:member'];
    });
}

export const resetGame = async (gameid, sport) => {
    return await asyncRequest(async () => {
        const formData = new FormData();
        formData.append('gameid', gameid);
        const response = await api.post(`/${sport}/reset_game`,formData,
            {
                headers: getHeader('ld+json'),
            }
        );

        return response.data;
    });
}

export const updateStream = async (gameid, sport, userId) => {
    return await asyncRequest(async () => {
        const formData = new FormData();
        formData.append('userid', userId);
        formData.append('gameid', gameid);
        formData.append('sportName', sport);
        const response = await api.post(`/update_active_stream`,formData,
            {
                headers: getHeader('ld+json'),
            }
        );

        return response.data;
    });
}

export const SendGameUpdate = async (gameid,formData,sport) => {
    return await asyncRequest(async () => {
        const response = await api.post(`/${sport}/${gameid}/handle_game`,formData,
            {
                headers: getHeader('ld+json'),
            }
        );

        return response.data;
    });
}

export const SendPointsUpdate = async (gameid,formData,sport) => {
    return await asyncRequest(async () => {
        const response = await api.post(`/${sport}/${gameid}/handle_points`,formData,
            {
                headers: getHeader('ld+json'),
            }
        );

        return response.data;
    });
}

export const undoPoints = async (gameid,sport) => {
    return await asyncRequest(async () => {
        const response = await api.post(`/${sport}/${gameid}/undo_points`,
            {
                headers: getHeader('ld+json'),
            }
        );

        return response.data;
    });
}

export const saveGame = async (gameid,sport) => {
    return await asyncRequest(async () => {
        const response = await api.post(`/${sport}/${gameid}/saveGame`,
            {
                headers: getHeader('ld+json'),
            }
        );

        return response.data;
    });
}

export const redoPoints = async (gameid,sport) => {
    return await asyncRequest(async () => {
        const response = await api.post(`/${sport}/${gameid}/redo_points`,
            {
                headers: getHeader('ld+json'),
            }
        );

        return response.data;
    });
}