import React, {useState} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import {createNewGame} from "../../../api/request/sportRequest";
import {useNavigate} from "react-router-dom";
import {renderCheckboxField, RenderPlayerInput} from "../../common/InputComponent";

const TenisGame = ({ showModal, handleCloseModal, id, userData}) => {
    const navigate = useNavigate();
    const [gameType, setGameType] = useState('individual'); // Estado para almacenar el tipo de juego seleccionado
    const [player1, setPlayer1] = useState(null)
    const [player2, setPlayer2] = useState(null)


    const handleNewGame = async() => {
        const formData = new FormData();

        formData.append('userid', userData.id)
        formData.append('player_one', player1);
        formData.append('player_two', player2);

        if(gameType == 'individual'){
            formData.append('individual', true);
        }else{
            formData.append('individual', true);
        }

        if(player1 !== null || player2 !== null){
            await createNewGame(formData, 'tenis').then((response) => {
                if (response.status == 200){
                    navigate('/sport/'+id+'/game/'+response.data.id);
                }
            })
        }else{
            alert('Por favor completar los nombres');
        }

    }

    const handleGameTypeChange = (event) => {
        setGameType(event.target.value);
    }

    return (
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Nuevo Partido</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label className={'mt-4'}>Tipo de Juego</Form.Label>
                        <Form.Group>
                            {renderCheckboxField('individual', gameType, handleGameTypeChange)}
                            {renderCheckboxField('parejas', gameType, handleGameTypeChange)}
                        </Form.Group>
                    </Form.Group>

                    <RenderPlayerInput
                        gameType={gameType}
                        controlId="player1"
                        playerNumber={1}
                        value={player1}
                        onChange={(event) => setPlayer1(event.target.value)}
                    />
                    <RenderPlayerInput
                        gameType={gameType}
                        controlId="player2"
                        playerNumber={2}
                        value={player2}
                        onChange={(event) => setPlayer2(event.target.value)}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleNewGame}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TenisGame;
