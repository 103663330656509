import React, {useEffect, useRef, useState} from 'react';
import {Container, Row, Col, Card, Button} from 'react-bootstrap';
import {getGameScore, getPreviousAndNext} from "../../api/request/sportRequest";
import {useParams} from "react-router-dom";
import '../../styles/game.css';
import {
    redoPoints,
    resetGame,
    sendGameScore,
    SendGameUpdate,
    SendPointsUpdate,
    undoPoints,
    saveGame, updateStream
} from "../../api/request/scoreRequest";
import useMercure from "../../hooks/MercureConection";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faRotateLeft, faRotateRight} from "@fortawesome/free-solid-svg-icons";
import GameButtons from "../../components/modals/GameButtons";
import HandleKeyboard from "../../hooks/HandleKeyboard";
import {fetchShortcuts} from "../../api/request/shortcutsRequest";
import ChooseServe from "../../components/modals/ChooseServe";
import {filterNullValues} from "../../utils/filterValues";

const PadelGamePage = () => {

    let timeoutId;
    const { gameid } = useParams();
    const [score, setScore] = useState([])
    const [undoPoint, setUndoPoint] = useState(false)
    const [redoPoint, setRedoPoint] = useState(false)
    const [mobileView, setMobileView] = useState(() => window.innerWidth < 770);
    const [showButtons, setShowButtons] = useState(false);
    const [showChooseServe, setShowChooseServe] = useState()

    const timeoutRef = useRef(null);

    const padelScore = useRef(null);
    const padelButtons = useRef(null);


    const [shortcuts, setShortcuts] = useState({});

    useEffect(() => {
        const fetchShortcutsData = async () => {
            let storedShortcuts = localStorage.getItem("shortcuts");
            if (!storedShortcuts) {
                const user = JSON.parse(localStorage.getItem("userData"));
                const response = await fetchShortcuts(user.id);
                storedShortcuts = response ? localStorage.getItem("shortcuts") : "{}";
            }
            setShortcuts(JSON.parse(storedShortcuts));
        };

        fetchShortcutsData();
    }, []);


    const executeAction = (action) => {
        const funct = actionsMap[action];
        if (funct) {
            funct();
        }
    };


    useEffect(() => {
        fetchData();

        const handleResize = () => {
            setMobileView(window.innerWidth < 770);
            if(window.innerWidth > 770)
                setShowButtons(false);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleShowButtons = () => {
        setShowButtons((prevShowButtons) => !prevShowButtons);
    };

    const fetchData = async () => {
        await getGameScore(gameid, 'padel_games').then((response) => {
            if (response.status === 200) {
                setScore(filterNullValues(response.data));
                if(!response.data.saque){
                    setShowChooseServe(true)
                }
            }
        });
        await getPreviousAndNext(gameid, 'padel').then((response) => {
            setUndoPoint(response.previousPoint);
            setRedoPoint(response.nextPoint);
        });
    };

    const { data, isConnected } = useMercure(`padel_games/${gameid}`);

    useEffect(() => {
        if (!isConnected) {
            const interval = setInterval(fetchData, 1000);
            return () => clearInterval(interval);
        } else {
            if (data && data !== score) {
                setScore(filterNullValues(data));
            }
        }
    }, [isConnected, data]);

    const handleName = (e, player) => {
        const newValue = e.target.value;

        // Actualizamos el estado localmente
        setScore((prevScore) => ({
            ...prevScore,
            [player]: newValue,
        }));

        // Limpiamos el timeout anterior
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        // Configuramos un nuevo timeout
        timeoutRef.current = setTimeout(async () => {
            const latestScore = { ...score, [player]: newValue }; // Aseguramos usar el valor más reciente
            await sendGameScore(gameid, latestScore, 'padel');
        }, 2000);
    };

    const handleServe = async (player) => {
        let newGame = { ...score };
        newGame['saque'] = player;
        if (!isConnected) {
            setScore(newGame);
        }
        if(showChooseServe){
            setShowChooseServe(false)
        }
        await sendGameScore(gameid, newGame, 'padel');
    };

    const handleGame = async (player, action) => {
        const formData = new FormData();
        formData.append('gameid', gameid);
        formData.append('player', player);
        formData.append('action', action);
        await SendGameUpdate(gameid, formData,'padel').then((response) => {
            setUndoPoint(response.previousPoint);
            setRedoPoint(response.nextPoint);
        });
    };

    const handlePoint = async (player, action) => {
        const formData = new FormData();
        formData.append('gameid', gameid);
        formData.append('player', player);
        formData.append('action', action);
         await SendPointsUpdate(gameid, formData,'padel').then((response) => {
             setUndoPoint(response.previousPoint);
             setRedoPoint(response.nextPoint);
         });
    };

    const handleUndo = async () => {
        await undoPoints(gameid,'padel').then((response) => {
            setUndoPoint(response.previousPoint);
            setRedoPoint(response.nextPoint);
        });
    }
    
    const handleSave = async () => {
        await saveGame(gameid,'padel').then((response) => {

            // navigate('/sports/'+id+'/game/'+response.data.id);
        });
    }
    const handleUpdateStream = async () => {
        const user = JSON.parse(localStorage.getItem('userData'));
        await updateStream(gameid, 'padel', user.id);
    }

    const handleReset = async () => {
        await resetGame(gameid, 'padel').then((response) => {
            setUndoPoint(response.previousPoint);
            setRedoPoint(response.nextPoint);
            fetchData();
        });
    };

    const handleRedo = async () => {
        await redoPoints(gameid,'padel').then((response) => {
            setUndoPoint(response.previousPoint);
            setRedoPoint(response.nextPoint);
        });
    }


    useEffect(() => {
        if (showButtons === true && padelScore.current) {
            padelScore.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest"
            });
        } else if (showButtons === true && padelButtons.current) {
            padelButtons.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [showButtons]); // Ejecuta este efecto cuando `mobileView` cambie


    const handleCloseModal = () => { setShowButtons(false)}
    const handleCloseServeModal = () => { setShowChooseServe(false)}


    const actionsMap = {
        toggleServe: () => handleServe(score.saque === 1 ? 2 : 1),
        addGameP1: () => handleGame(1, "increase"),
        subtractGameP1: () => handleGame(1, "decrease"),
        addPointP1: () => handlePoint(1, "increase"),
        subtractPointP1: () => handlePoint(1, "decrease"),
        addGameP2: () => handleGame(2, "increase"),
        subtractGameP2: () => handleGame(2, "decrease"),
        addPointP2: () => handlePoint(2, "increase"),
        subtractPointP2: () => handlePoint(2, "decrease"),
        undo: handleUndo,
        redo: handleRedo,
        reset: handleReset,
        updateStream: handleUpdateStream,
        save: handleSave,
    };



    return (
        <Container>
            <ChooseServe
                showButtons={showChooseServe}
                gameid={gameid}
                handleCloseModal={handleCloseServeModal}
                handleServe={handleServe}
                playerOne={score.playerOne}
                playerTwo={score.playerTwo}
            />
            <Container style={{placeItems: "center"}}>
                <Row className="mt-3 w-100">
                    <Col className="game-container">
                        <Row className="score-table-container">
                            <Col className="mt-3">
                                <Card ref={padelScore}>
                                    <Card.Header className='d-flex justify-content-center p-3'>
                                        <div> Partido </div>
                                        { mobileView && !score.isSaved &&
                                            <div className='title-button'>
                                                <Button className='p-1' onClick={() => {handleShowButtons()}}>
                                                    {showButtons ? "Hide Buttons" : "Show Buttons"}
                                                </Button>
                                            </div>
                                        }
                                    </Card.Header>
                                    <Card.Body style={{
                                        backgroundColor: '#00ff00',
                                        color: '#fff',
                                        fontSize: '40px',
                                        textAlign: 'left'}}>
                                            {score && (
                                                <table className="tabla-puntos-1">
                                                    <tbody>
                                                    <tr onClick={() => handlePoint(1, 'increase')}>
                                                        {score.playerOne && (
                                                            <th className="parejas">{score.playerOne.toUpperCase()}</th>
                                                        )}

                                                        {!score.isSaved && (
                                                            <th className="saque">{score.saque === 1 ? '🟡' : ''}</th>
                                                        )}
                                                        {score.p11s >= 0 && score.p11s != null && <th className="set">{score.p11s}</th>}
                                                        {score.p12s >= 0 && score.p12s != null  && <th className="set">{score.p12s}</th>}
                                                        {score.p13s >= 0 && score.p13s != null  && <th className="set">{score.p13s}</th>}
                                                        {score.p1ps  >= -1 && (score.p1ps === 40 && score.p2ps === 40 && score.mode === 'oro' ?
                                                            <th className="oro">{score.p1ps}</th>
                                                            : score.isTieBreak === true ? <th className="tbr">{score.p1ps}</th>
                                                            : <th className="puntos">{score.p1ps === -1 ? 'AV' : score.p1ps}</th>)
                                                        }
                                                    </tr>
                                                    <tr onClick={() => handlePoint(2, 'increase')}>
                                                        {score.playerTwo && (
                                                            <th className="parejas">{score.playerTwo.toUpperCase()}</th>
                                                        )}

                                                        {!score.isSaved && (
                                                            <th className="saque">{score.saque === 2 ? '🟡' : ''}</th>
                                                        )}
                                                        {score.p21s >= 0 && score.p21s != null && <th className="set">{score.p21s}</th>}
                                                        {score.p22s >= 0 && score.p22s != null && <th className="set">{score.p22s}</th>}
                                                        {score.p23s >= 0 && score.p23s != null && <th className="set">{score.p23s}</th>}
                                                        {score.p2ps >= -1 && (score.p1ps === 40 && score.p2ps === 40 && score.mode === 'oro' ?
                                                            <th className="oro">{score.p2ps}</th>
                                                            : score.isTieBreak === true ? <th className="tbr">{score.p2ps}</th>
                                                            : <th className="puntos">{score.p2ps === -1 ? 'AV' : score.p2ps}</th>)
                                                        }
                                                    </tr>
                                                    </tbody>

                                                </table>
                                            )}

                                        {window.innerWidth < 1100  && (
                                            <Button className='redo-mobile' disabled={!undoPoint} onClick={handleUndo}>
                                                <FontAwesomeIcon icon={faRotateLeft}/>
                                            </Button>
                                        )}
                                        </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        { !score.isSaved && !mobileView && (
                            <Row className="mt-3">
                                <Col>
                                    <Card ref={padelButtons}>
                                        <Card.Header>Botonera</Card.Header>
                                        <Card.Body>
                                            <div className='buttons-container'>
                                                <div>
                                                    <p>Player 1</p>
                                                    <input type="text" value={score.playerOne}
                                                           onChange={(e) => handleName(e, 'playerOne')}
                                                           className='name-player'/>
                                                </div>
                                                <div className='set-container'>
                                                    <p>Serve</p>
                                                    <Button onClick={() => handleServe(1)}>🟡</Button>
                                                </div>
                                                <div className='set-container'>
                                                    <p>Games</p>
                                                    <div className='d-flex flex-wrap'>
                                                        <Button className='m-1' onClick={() => handleGame(1, 'decrease')}>-</Button>
                                                        <Button className='m-1' onClick={() => handleGame(1, 'increase')}>+</Button>
                                                    </div>
                                                </div>
                                                <div className='set-container'>
                                                    <p>Points</p>
                                                    <div className='d-flex flex-wrap'>
                                                        <Button className='m-1' onClick={() => handlePoint(1, 'decrease')}>-</Button>
                                                        <Button className='m-1' onClick={() => handlePoint(1, 'increase')}>+</Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='buttons-container'>
                                                <div>
                                                    <p>Player 2</p>
                                                    <input type="text" value={score.playerTwo}
                                                           onChange={(e) => handleName(e, 'playerTwo')}
                                                           className='name-player'/>
                                                </div>
                                                <div className='set-container'>
                                                    <p className='hidden-title'>Serve</p>
                                                    <Button onClick={() => handleServe(2)}>🟡</Button>
                                                </div>
                                                <div className='set-container'>
                                                    <p className='hidden-title'>Games</p>
                                                    <div className='d-flex flex-wrap'>
                                                        <Button className='m-1' onClick={() => handleGame(2, 'decrease')}>-</Button>
                                                        <Button className='m-1' onClick={() => handleGame(2, 'increase')}>+</Button>
                                                    </div>
                                                </div>
                                                <div className='set-container'>
                                                    <p className='hidden-title'>Points</p>
                                                    <div className='d-flex flex-wrap'>
                                                        <Button className='m-1' onClick={() => handlePoint(2, 'decrease')}>-</Button>
                                                        <Button className='m-1' onClick={() => handlePoint(2, 'increase')}>+</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                        <div className={'d-flex justify-content-center'}>
                                            <div className='buttons-container unredo-button p-2'>
                                                <Button disabled={!undoPoint} onClick={handleUndo}>
                                                    <FontAwesomeIcon icon={faRotateLeft}/>
                                                </Button>
                                            </div>
                                            <div className='buttons-container reset-button p-2'>
                                                <Button onClick={handleReset}>Reset</Button>
                                            </div>
                                            <div className='buttons-container unredo-button p-2'>
                                                <Button disabled={!redoPoint} onClick={handleRedo}>
                                                    <FontAwesomeIcon icon={faRotateRight}/>
                                                </Button>
                                            </div>
                                        </div>

                                        <div className='d-flex justify-content-center'>
                                            <div className='bottom-button p-2 mb-3'>
                                                <Button className='p-3' onClick={handleUpdateStream}>Update Stream</Button>
                                            </div>
                                            <div className='bottom-button p-2 mb-3'>
                                                <Button className='p-3' onClick={handleSave}>Save</Button>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </Container>
            <GameButtons
                showButtons={showButtons}
                gameid={gameid}
                handleCloseModal={handleCloseModal}
                score={score}
                handleServe={handleServe}
                handleName={handleName}
                handleGame={handleGame}
                handlePoint={handlePoint}
                handleReset={handleReset}
                handleUpdateStream={handleUpdateStream}
                handleUndo={handleUndo}
                handleRedo={handleRedo}
                handleSave={handleSave}
                undoPoint={undoPoint}
                redoPoint={redoPoint}
            />
            <HandleKeyboard shortcuts={shortcuts} executeAction={executeAction} />
        </Container>
    );
};

export default PadelGamePage;
