import api, {asyncRequest, getHeader} from '../api';

export const resetShortcuts = async () => {
    return await asyncRequest(async () => {

        const userData = JSON.parse(localStorage.getItem('userData'));
        const formData = new FormData();
        formData.append('user_id', userData.id);
        const response = await api.post('/shortcuts/reset',formData,
            {
                headers: getHeader('merge-patch+json'),
            });

        console.log(JSON.parse(response.data.shortcuts));
        localStorage.setItem("shortcuts", response.data.shortcuts);
        return JSON.parse(response.data.shortcuts);
    });
}

export const fetchShortcuts = async (userId) => {
    return await asyncRequest(async () => {
        const response = await api.get(`/shortcuts/fetch/${userId}`,
            {
                headers: getHeader('merge-patch+json'),
            });

        localStorage.setItem("shortcuts", JSON.stringify(response.data));
        return response;
    });
}

export const updateShortcuts = async (updateShortcuts) => {
    return await asyncRequest(async () => {
        const userData = JSON.parse(localStorage.getItem('userData'));

        const response = await api.patch(`/shortcuts/${userData.shortcutId}`,updateShortcuts,
            {
                headers: getHeader('merge-patch+json'),
            });

        localStorage.setItem("shortcuts", JSON.stringify(response.data));
        return response;
    });
}
