import api, {asyncRequest, getHeader} from "../api";

export const getHistoryGamesByUser = async(userid, sport) => {
    return await asyncRequest(async () => {
        const formData = new FormData();

        formData.append('userid', userid)

        const response = await api.post(`/history/${sport}_games_user`,
            formData,
            {
                headers: getHeader('ld+json'),
            }
        );
        return response;
    });
}