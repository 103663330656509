import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import {getGameScore} from "../api/request/sportRequest";
import {useNavigate, useParams} from "react-router-dom";
import '../styles/game.css';
import useMercure from "../hooks/MercureConection";
import {fetchStreamUser} from "../api/request/agentRequest";
const StreamPage = () => {

    const navigate = useNavigate();
    const { username } = useParams();
    const [score, setScore] = useState([])
    const [streamer, setStreamer] = useState([])

    useEffect(() => {
        fetchDataUser();
    }, [username]);


    const fetchData = (gameId, sportName) => {
        if(gameId){
            getGameScore( gameId, sportName).then((response) => {
                if (response.status === 200) {
                    setScore(filterNullValues(response.data));
                }
            });
        }
    };

    const fetchDataUser = async () => {
        await fetchStreamUser(username).then((response) => {
            if (response && response.status === 404) {
                navigate('/404');
                return;
            }
            setStreamer(() => { return {sportName: response.sportName, gameId: response.gameId}});
            fetchData(response.gameId, response.sportName);
            handleChangeGame(response.gameId, response.sportName)
        });
    };

    const { data: user, isConnected: userIsConnected } = useMercure(`users/${username}`);

    useEffect(() => {
        if (!userIsConnected) {
            const interval = setInterval(fetchDataUser, 1000);
            return () => clearInterval(interval);
        } else {
            if(streamer && user && (streamer.sportName !== user.sportName || streamer.gameId !== user.gameId)){
                handleChangeGame(user.gameId, user.sportName)
                fetchDataUser();
            }
        }
    }, [userIsConnected, user]);


    const { data: gameData, isConnected: gameIsConnected, updateTopic } = useMercure(`${streamer.sportName}/${streamer.gameId}`);


    useEffect(() => {
        if (!gameIsConnected) {
            const interval = setInterval(fetchData, 1000);
            return () => clearInterval(interval);
        } else {
            if (gameData && gameData !== score) {
                setScore(filterNullValues(gameData));
            }
        }
    }, [gameIsConnected, gameData]);


    const handleChangeGame = (newGameId, newSportName) => {
        const newTopic = `${newSportName}/${newGameId}`;
        updateTopic(newTopic); // Cierra la conexión actual y comienza una nueva
    };

// Filtra valores nulos o indefinidos
    const filterNullValues = (gameData) => {
        const filteredData = {};
        for (let key in gameData) {
            if (gameData[key] !== null && gameData[key] !== undefined) {
                filteredData[key] = gameData[key];
            }
        }
        return filteredData;
    };

    return (
        <Container>
            <Container style={{placeItems: "center"}}>
                <Row className="mt-3 w-100">
                    <Col className="game-container">
                        <Row className="score-table-container">
                            <Col className="mt-3">
                                <Card>
                                    <Card.Header>Partido </Card.Header>
                                    <Card.Body style={{
                                        backgroundColor:'#00ff00',
                                        color: '#fff',
                                        fontSize: '40px',
                                        textAlign: 'left'}}>
                                        {score && (
                                            <table className="tabla-puntos-1">
                                                <tbody>
                                                <tr>
                                                    {score.playerOne && (
                                                        <th className="parejas">{score.playerOne.toUpperCase()}</th>
                                                    )}
                                                    <th className="saque">{score.saque === 1 ? '🟡' : ''}</th>
                                                    {score.p11s >= 0 && score.p11s != null  && <th className="set">{score.p11s}</th>}
                                                    {score.p12s >= 0 && score.p12s != null  && <th className="set">{score.p12s}</th>}
                                                    {score.p13s >= 0 && score.p13s != null  && <th className="set">{score.p13s}</th>}
                                                    {score.p1ps  >= -1 && (score.p1ps === 40 && score.p2ps === 40 && score.mode === 'oro' ?
                                                        <th className="oro">{score.p1ps}</th>
                                                        : score.isTieBreak === true ? <th className="tbr">{score.p1ps}</th>
                                                        : <th className="puntos">{score.p1ps === -1 ? 'AV' : score.p1ps}</th>)
                                                    }
                                                </tr>
                                                <tr>
                                                    {score.playerTwo && (
                                                        <th className="parejas">{score.playerTwo.toUpperCase()}</th>
                                                    )}
                                                    <th className="saque">{score.saque === 2 ? '🟡' : ''}</th>
                                                    {score.p21s >= 0 && score.p21s != null && <th className="set">{score.p21s}</th>}
                                                    {score.p22s >= 0 && score.p22s != null && <th className="set">{score.p22s}</th>}
                                                    {score.p23s >= 0 && score.p23s != null && <th className="set">{score.p23s}</th>}
                                                    {score.p2ps >= -1 && (score.p1ps === 40 && score.p2ps === 40 && score.mode === 'oro' ?
                                                        <th className="oro">{score.p2ps}</th>
                                                        : score.isTieBreak === true ? <th className="tbr">{score.p2ps}</th>
                                                        : <th className="puntos">{score.p2ps === -1 ? 'AV' : score.p2ps}</th>)
                                                    }
                                                </tr>
                                                </tbody>
                                            </table>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default StreamPage;
