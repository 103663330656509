import React, {useEffect, useRef, useState} from 'react';
import {Modal, Button, Table} from 'react-bootstrap';
import '../../styles/game.css';
import {resetShortcuts, updateShortcuts} from "../../api/request/shortcutsRequest";
import {forEach} from "react-bootstrap/ElementChildren";
import {faEdit, faRotateLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Shortcuts = ({showShortcuts, handleCloseModal}) => {

    const [shortcuts, setShortcuts] = useState(JSON.parse(localStorage.getItem('shortcuts')));
    const [showEditModal, setShowEditModal] = useState(false); // Controla la visibilidad del modal de edición
    const [currentAction, setCurrentAction] = useState(null); // Acción en edición

    const handleResetShorcuts = async () => {
        await resetShortcuts().then((response) => {
            setShortcuts(response)
        });
    }

    const formatCamelCase = (text) => {
        return text
            .replace(/([a-z])([A-Z])/g, '$1 $2') // Divide camelCase en palabras separadas
            .split(' ') // Divide en palabras
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitaliza cada palabra
            .join(' '); // Une las palabras con espacios
    };

    const handleEdit = (action) => {
        setCurrentAction(action); // Guarda qué acción está en edición
        setShowEditModal(true); // Muestra el modal para capturar teclas
    };

    const handleKeyPress = async (event) => {
        var pressedKey = `${event.ctrlKey ? "Ctrl+" : ""}${event.shiftKey ? "Shift+" : ""}${event.altKey ? "Alt+" : ""}${event.key}`;

        // Actualizar el shortcut con la nueva combinación
        const updatedShortcuts = {
            ...shortcuts,
            [currentAction]: pressedKey, // Reemplaza el shortcut actual
        };

        setShortcuts(updatedShortcuts);

        await updateShortcuts({[currentAction]: pressedKey})

        localStorage.setItem('shortcuts', JSON.stringify(updatedShortcuts));
        setShowEditModal(false); // Cierra el modal
        setCurrentAction(null); // Limpia la acción actual
    };

    useEffect(() => {
        if (showEditModal) {
            // Agrega el event listener para capturar teclas
            window.addEventListener("keydown", handleKeyPress);
        }
        return () => {
            // Elimina el event listener cuando el modal se cierra
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, [showEditModal]);

    return (
        <>
            {/* Modal principal */}
            <Modal
                className='w-100'
                show={showShortcuts}
                onHide={handleCloseModal}
                style={{
                    filter: showEditModal ? "blur(2px)" : "none", // Aplica blur si el modal de edición está visible
                    pointerEvents: showEditModal ? "none" : "auto", // Desactiva la interacción con el modal principal
                }}>
                <Modal.Header closeButton>
                    <Modal.Title>Shortcuts Config</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Action</th>
                            <th>Command</th>
                            <th>Edit</th>
                        </tr>
                        </thead>
                        <tbody>
                        {shortcuts && Object.entries(shortcuts).map(([action, command], index) => (
                            <tr key={index}>
                                <td>{action && formatCamelCase(action)}</td>
                                <td>{command}</td>
                                <td>
                                    <Button onClick={() => handleEdit(action)}><FontAwesomeIcon
                                        icon={faEdit}/></Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <Button variant="secondary" onClick={handleResetShorcuts}>
                        Reset Shortcuts
                    </Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de edición */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Shortcut</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Presione las teclas para el atajo de: <strong>{currentAction && formatCamelCase(currentAction)}</strong>
                    </p>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Shortcuts;
