import React from 'react';
import {Modal, Button, Row, Col, Container} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotateLeft, faRotateRight} from "@fortawesome/free-solid-svg-icons";
import '../../styles/game.css';

const GameButtons = ({ showButtons, gameid, handleCloseModal, score, handleServe, handleName, handleGame, handlePoint, handleReset, handleUpdateStream, handleUndo, handleRedo, handleSave, redoPoint, undoPoint}) => {
    return (
        <Modal className='w-100' show={showButtons} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Botonera</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container style={{placeItems: "center"}}>
                    <Row>
                        <Col className="game-container">
                            { !score.isSaved && (
                                <Row>
                                    <Col className='buttons-container align-items-center' xs={12}>
                                        <Col xs={6}>
                                            <table className="tabla-puntos-1 preview">
                                                <tbody>
                                                <tr className='preview-row'>
                                                    <th className="set preview-field">{score.p11s ? score.p11s : 0}</th>
                                                    <th className="set preview-field">{score.p12s ? score.p12s : 0}</th>
                                                    <th className="set preview-field">{score.p13s ? score.p13s : 0}</th>
                                                    {score.p1ps >= -1 && (score.p1ps === 40 && score.p2ps === 40 && score.mode === 'oro' ?
                                                        <th className="oro preview-field">{score.p1ps}</th>
                                                        : score.isTieBreak === true ?
                                                            <th className="tbr preview-field">{score.p1ps}</th>
                                                            :
                                                            <th className="puntos preview-field">{score.p1ps === -1 ? 'AV' : score.p1ps}</th>)
                                                    }
                                                    <th className="saque preview-field" width={20}>{score.saque === 1 ? '🟡' : ''}</th>

                                                </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                        <Col xs={6} style={{justifyItems: 'flex-end'}}>
                                            <table className="tabla-puntos-1 preview">
                                                <tbody>
                                                <tr className='preview-row'>
                                                    <th className="saque preview-field">{score.saque === 2 ? '🟡' : ''}</th>
                                                    <th className="set preview-field">{score.p21s ? score.p21s : 0}</th>
                                                    <th className="set preview-field">{score.p22s ? score.p22s : 0}</th>
                                                    <th className="set preview-field">{score.p23s ? score.p23s : 0}</th>
                                                    {score.p2ps >= -1 && (score.p1ps === 40 && score.p2ps === 40 && score.mode === 'oro' ?
                                                        <th className="oro preview-field">{score.p2ps}</th>
                                                        : score.isTieBreak === true ?
                                                            <th className="tbr preview-field">{score.p2ps}</th>
                                                            :
                                                            <th className="puntos preview-field">{score.p2ps === -1 ? 'AV' : score.p2ps}</th>)
                                                    }
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Col>
                                    <Col className='buttons-container justify-content-sm-between align-items-center' xs={12}>
                                        <Col xs={5}>
                                            <p>Player 1</p>
                                            <input type="text" value={score.playerOne}
                                                   onChange={(e) => handleName(e, 'playerOne')}
                                                   className='name-player col-12'/>
                                        </Col>
                                        <Col xs={5}>
                                            <p>Player 2</p>
                                            <input type="text" value={score.playerTwo}
                                                   onChange={(e) => handleName(e, 'playerTwo')}
                                                   className='name-player col-12'/>
                                        </Col>
                                    </Col>
                                    <Col className='buttons-container mt-4 align-items-center' xs={12}>
                                        <div className='set-container'>
                                            <Button onClick={() => handleServe(1)}>🟡</Button>
                                        </div>
                                        <div><p>Serve</p></div>
                                        <div className='set-container'>
                                            <p className='hidden-title'>Serve</p>
                                            <Button onClick={() => handleServe(2)}>🟡</Button>
                                        </div>
                                    </Col>

                                    <Col className='buttons-container mt-4 align-items-center'
                                         xs={12}>
                                        <div className='set-container'>
                                            <div className='d-flex flex-wrap justify-content-center'>
                                                <Button className='m-1'
                                                        onClick={() => handleGame(1, 'decrease')}>-</Button>
                                                <Button className='m-1'
                                                        onClick={() => handleGame(1, 'increase')}>+</Button>
                                            </div>
                                        </div>
                                        <div className='row-name-buttons'><p>Games</p></div>
                                        <div className='set-container'>
                                            <p className='hidden-title'>Games</p>
                                            <div className='d-flex flex-wrap justify-content-center'>
                                                <Button className='m-1'
                                                        onClick={() => handleGame(2, 'decrease')}>-</Button>
                                                <Button className='m-1'
                                                        onClick={() => handleGame(2, 'increase')}>+</Button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className='buttons-container mt-4 align-items-center' xs={12}>

                                        <div className='set-container'>
                                            <div className='d-flex flex-wrap justify-content-center'>
                                                <Button className='m-1'
                                                        onClick={() => handlePoint(1, 'decrease')}>-</Button>
                                                <Button className='m-1'
                                                        onClick={() => handlePoint(1, 'increase')}>+</Button>
                                            </div>
                                        </div>
                                        <div className='row-name-buttons'><p>Points</p></div>
                                        <div className='set-container'>
                                            <p className='hidden-title'>Points</p>
                                            <div className='d-flex flex-wrap justify-content-center'>
                                                <Button className='m-1'
                                                        onClick={() => handlePoint(2, 'decrease')}>-</Button>
                                                <Button className='m-1'
                                                        onClick={() => handlePoint(2, 'increase')}>+</Button>
                                            </div>
                                        </div>

                                    </Col>

                                    <Col className='buttons-container align-items-center' xs={5}>


                                    </Col>
                                    <Col className={'d-flex justify-content-around'} xs={12}>
                                        <div className='buttons-container unredo-button p-2'>
                                            <Button disabled={!undoPoint} onClick={handleUndo}><FontAwesomeIcon
                                                icon={faRotateLeft}/></Button>
                                        </div>
                                        <div className='buttons-container reset-button p-2'>
                                        <Button onClick={handleReset}>Reset</Button>
                                        </div>
                                        <div className='buttons-container unredo-button p-2'>
                                            <Button disabled={!redoPoint} onClick={handleRedo}><FontAwesomeIcon
                                                icon={faRotateRight}/></Button>
                                        </div>
                                    </Col>

                                    <Col className='d-flex justify-content-center' xs={12}>
                                        <div className='bottom-button p-2 mb-3'>
                                            <Button className='p-3' onClick={handleUpdateStream}>Update Stream</Button>
                                        </div>
                                        <div className='bottom-button p-2 mb-3'>
                                            <Button className='p-3' onClick={handleSave}>Save</Button>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default GameButtons;
