import React, {useState} from 'react';
import '../styles/header.css';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import {useAuth} from "../context/AuthContext";
import sanRemoImage from '../images/sanRemo.ico';
import Shortcuts from "./modals/Shortcuts";
import {fetchStreamers} from "../api/request/agentRequest";
import AsyncSelect from "react-select/async";
import {useNavigate} from "react-router-dom";

const Header = () => {
    const navigate = useNavigate();
    const { isLoggedIn, logout } = useAuth();
    const [showShortcuts, setShowShortcuts] = useState(false);
    const [menuIsOpen, setMenuIsOpen] = useState(false)

    const userData = JSON.parse(localStorage.getItem('userData'));

    const sports = [{name: 'Padel'},{name: 'Tenis'}];

    const handleShowShortcuts = () => {
        setShowShortcuts((prevShowShortcuts) => !prevShowShortcuts);
    };

    const handleCloseModal = () => {
        setShowShortcuts(false);
    }

    const promiseOptions = (inputValue) =>{
        if(inputValue.length > 0){
            setMenuIsOpen(true)
            return new Promise((resolve) => {
                setTimeout(async () => {
                    const response = await fetchStreamers(inputValue); // Asegúrate de que retorne un array de objetos
                    // Mapea la respuesta para que cada opción tenga el formato { label, value }
                    const options = response.map((streamer) => ({
                        label: streamer.username,
                        value: streamer.username, // o el ID si prefieres
                    }));
                    resolve(options);
                }, 1000);
            });
        }else{
            setMenuIsOpen(false);

        }
    }


    const handleSelectChange = (selectedOption) => {
        if (selectedOption) {
            navigate(`/stream/${selectedOption.value}`);
        }
    };


    return (
        <header>
            <Navbar bg="light" expand="lg">
                <Navbar.Brand className="logo" href="/">
                    <img src={sanRemoImage} alt="San Remo"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav" className='navbar-container'>
                    <Nav className="mr-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        {sports && isLoggedIn &&
                            <NavDropdown className='mx-2' title="Deportes" id="sports-dropdown" >
                                {sports.map((sport, index) => (
                                    <NavDropdown className='mx-2' key={index} title={sport.name} id={`sport-dropdown-${index}`}
                                                 drop='end'>
                                        <NavDropdown.Item href={`/sports/${sport.name.toLowerCase()}`}>Partidos</NavDropdown.Item>
                                        {/*<NavDropdown.Item href={`/sports/${sport.id}/tournaments`}>Torneos</NavDropdown.Item>*/}
                                        <NavDropdown.Item
                                            href={`/sports/${sport.name.toLowerCase()}/history`}>Historial</NavDropdown.Item>
                                    </NavDropdown>
                                ))}
                            </NavDropdown>
                        }
                        { isLoggedIn && userData &&
                            <Nav>
                                <Nav.Link href={`/stream/${userData.username}`}>Streaming</Nav.Link>
                            </Nav>
                        }
                        <Nav className='users-select-container'>
                            <AsyncSelect
                                className='users-select'
                                placeholder="Buscar usuarios..."
                                loadingMessage={() => "Cargando usuarios..."}
                                noOptionsMessage={() => "No se encontraron usuarios"}
                                cacheOptions
                                defaultOptions
                                menuIsOpen={menuIsOpen}
                                loadOptions={promiseOptions}
                                onChange={handleSelectChange}
                                openMenuOnFocus={false}
                                onMenuClose={() => setMenuIsOpen(false)}/>
                        </Nav>
                    </Nav>

                    <h4>WIP</h4>
                    {isLoggedIn ? (
                        <Nav>
                            <NavDropdown className='mx-2' title="Mi cuenta" id="account-dropdown" drop='start'>
                                <NavDropdown.Item href="/account">Perfil</NavDropdown.Item>
                                <NavDropdown.Item onClick={handleShowShortcuts}>Shortcuts</NavDropdown.Item>
                                {/*<NavDropdown.Item href="/settings">Configuraciones</NavDropdown.Item>*/}
                                <NavDropdown.Divider/>
                                <NavDropdown.Item href="/logout" onClick={(e) => {
                                    e.preventDefault();
                                    logout();
                                }}>Cerrar sesión</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    ) : (
                        <Nav>
                            <Nav.Link href="/register">Registrarse</Nav.Link>
                            <Nav.Link href="/login">Iniciar sesión</Nav.Link>
                        </Nav>
                    )}
                </Navbar.Collapse>
            </Navbar>
            <Shortcuts
                showShortcuts={showShortcuts}
                handleCloseModal={handleCloseModal} />
        </header>
    );
};

export default Header;
