import React from 'react';
import {Form} from 'react-bootstrap';

export const RenderPlayerInput = ({ gameType, controlId, playerNumber, value, onChange }) => {
    const isIndividual = gameType === 'individual';
    const label = isIndividual ? `Jugador ${playerNumber}` : `Pareja ${playerNumber}`;
    const placeholder = isIndividual
        ? `Nombre del Jugador ${playerNumber}`
        : `Nombre de la Pareja ${playerNumber}`;

    return (
        <Form.Group className="mt-4" controlId={controlId}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                className="mt-2"
                type="text"
                placeholder={placeholder}
                value={value || ''}
                onChange={onChange}
            />
        </Form.Group>
    );
};

export const renderCheckboxField = (value, compareValue, handleFunction) => (
    <Form.Check
        className="mt-2"
        inline
        type="radio"
        label={value.toUpperCase()}
        value={value}
        checked={compareValue === value}
        onChange={handleFunction}
    />
);
