import React, {useCallback, useEffect, useState} from 'react';
import {Container, Row, Col, Card, Button} from 'react-bootstrap';
import {getGamesByUser, getPadelGamesByUser, getTenisGamesByUser} from "../api/request/sportRequest";
import {useParams} from "react-router-dom";
import PadelGame from "../components/modals/new/PadelGame";
import TenisGame from "../components/modals/new/TenisGame";
import Grid from "../components/Grid";
import SquashGame from "../components/modals/new/SquashGame";

const modalComponents = {
    padel: PadelGame,
    tenis: TenisGame,
    // squash: SquashGame
}

const SportPage = () => {

    const { sport_name } = useParams();
    const userData = JSON.parse(localStorage.getItem('userData'));
    const [games, setGames] = useState([])
    const [showModal, setShowModal] = useState(false);

    const fetchGames = useCallback(async () => {
        try {
            const responseGames = await getGamesByUser(userData.id, sport_name);
            if (responseGames.status === 200) {
                setGames(responseGames.data);
            } else {
                setGames([]);
            }
        } catch (error) {
            console.error('Error fetching games:', error);
            setGames([]);
        }
    }, [userData.id, sport_name]);

    useEffect(() => {
        fetchGames();
    }, [fetchGames]);
    // id === '3' ? SquashGame :
    const ModalComponent = modalComponents[sport_name];
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <Container>
            <div className='sport-container'>
                <h1>{sport_name}</h1>
                <Button className='create-game-button' onClick={handleOpenModal}>Nuevo Partido</Button>
                {ModalComponent && (
                    <ModalComponent
                        showModal={showModal}
                        handleCloseModal={handleCloseModal}
                        id={sport_name}
                        userData={userData}
                    />
                )}
            </div>
            <Container>
                <Row className="mt-3">
                    <Col md={12}>
                        <Card>
                            <Grid data={games} history={false} updateData={fetchGames}></Grid>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default SportPage;
