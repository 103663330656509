import axios from "axios";
import {toast} from "sonner";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

export const asyncRequest = async (reqFunc) => {
    try {
        return await reqFunc();
    } catch (error) {
        if (error && error.response) {
            if (error.response.status === 401 || error.response.status === 404) {
                toast.error(error.response.data.message);
                return {'status': error.response.status}
            }
        }
    }
}
export const syncRequest = (reqFunc) => {
    try {
        reqFunc();
    }catch (error) {
        console.error('Fetch error: ', error);
    }
}

export const getHeader = (format = 'json') => {
    return {
        'Content-Type': `application/${format}`,
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
}

api.interceptors.response.use(response => response, async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config.url == '/token/refresh') {
        originalRequest._retry = true;  // marcar que este request ya intent? reautenticarse
        try {
            const refreshResponse = await api.post('/token/refresh', {
                refreshToken: localStorage.getItem('refreshToken')  // asumiendo que guardas el token de refresco aqu?
            });

            const { token, refreshToken } = refreshResponse.data;
            localStorage.setItem('jwt', token);
            localStorage.setItem('refreshToken', refreshToken);

            // Actualiza el token en la cabecera de la solicitud original
            originalRequest.headers['Authorization'] = `Bearer ${token}`;

            // Reintenta la solicitud original con el nuevo token
            return api(originalRequest);
        } catch (refreshError) {
            console.error('Unable to refresh token', refreshError);
            localStorage.removeItem('jwt');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('shortcuts');
            window.location.href = '/login';  // redirigir al usuario al login
            return Promise.reject(refreshError);
        }
    }
    return Promise.reject(error);
});

export default api;