import React, {useEffect, useRef, useState} from 'react';
import {Container, Row, Col, Card, Button} from 'react-bootstrap';
import {getGameScore} from "../../api/request/sportRequest";
import {useParams} from "react-router-dom";
import '../../styles/game.css';
import {
    resetGame,
    sendGameScore,
    SendGameUpdate,
    SendPointsUpdate
} from "../../api/request/scoreRequest";
import useMercure from "../../hooks/MercureConection";

const TenisGamePage = () => {

    let timeoutId;
    const { gameid } = useParams();
    const [score, setScore] = useState([])
    const timeoutRef = useRef(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        await getGameScore(gameid, 'tenis_games').then((response)=>{
            if(response.status === 200){
                setScore(filterNullValues(response.data));
            }
        })

    };


    const { data, isConnected } = useMercure(`tenis_games/${gameid}`);

    useEffect(() => {
        if (!isConnected) {
            const interval = setInterval(fetchData, 1000);
            return () => clearInterval(interval);
        } else {
            if (data && data !== score) {
                setScore(filterNullValues(data));
            }
        }
    }, [isConnected, data]);

    const filterNullValues = (data) => {
        let filteredData = {};
        for (let key in data) {
            if (data[key] !== null && data[key] !== undefined) {
                filteredData[key] = data[key];
            }
        }
        return filteredData;
    };

    const handleName = (e, player) => {
        const newValue = e.target.value;

        // Actualizamos el estado localmente
        setScore((prevScore) => ({
            ...prevScore,
            [player]: newValue,
        }));

        // Limpiamos el timeout anterior
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        // Configuramos un nuevo timeout
        timeoutRef.current = setTimeout(async () => {
            const latestScore = { ...score, [player]: newValue }; // Aseguramos usar el valor más reciente
            await sendGameScore(gameid, latestScore, 'tenis');
        }, 2000);
    };

    const handleServe = async (player) => {
        let newGame = { ...score };
        newGame['saque'] = player;
        if (!isConnected) {
            setScore(newGame);
        }
        await sendGameScore(gameid, newGame, 'tenis');
    };

    const handleGame = async (player, action) => {
        const formData = new FormData();
        formData.append('gameid', gameid);
        formData.append('player', player);
        formData.append('action', action);
        await SendGameUpdate(gameid, formData,'tenis');
    };

    const handlePoint = async (player, action) => {

        const formData = new FormData();
        formData.append('gameid', gameid);
        formData.append('player', player);
        formData.append('action', action);
        await SendPointsUpdate(gameid, formData,'tenis');
    };


    const handleReset = async () => {
        await resetGame(gameid, 'tenis').then(() => {
            fetchData();
        });
    };


    return (
        <Container>
            <Container style={{placeItems: "center"}}>
                <Row className="mt-3 w-100">
                    <Col className="game-container">
                        <Row className="score-table-container">
                            <Col className="mt-3">
                                <Card>
                                    <Card.Header>Partido </Card.Header>
                                    <Card.Body style={{
                                        backgroundColor:'#00ff00',
                                        color: '#fff',
                                        fontSize: '40px',
                                        textAlign: 'left'}}>
                                        {score && (
                                            <table className="tabla-puntos-1">
                                                <tbody>
                                                <tr>
                                                    {score.playerOne && (
                                                        <th className="parejas">{score.playerOne.toUpperCase()}</th>
                                                    )}
                                                    <th className="saque">{score.saque === 1 ? '🟡' : ''}</th>
                                                    {score.p11s >= 0 && score.p11s != null  && <th className="set">{score.p11s}</th>}
                                                    {score.p12s >= 0 && score.p12s != null  && <th className="set">{score.p12s}</th>}
                                                    {score.p13s >= 0 && score.p13s != null  && <th className="set">{score.p13s}</th>}
                                                    {score.p1ps  >= -1 && (score.isTieBreak === true ?
                                                        <th className="tbr">{score.p1ps}</th>
                                                        : <th className="puntos">{score.p1ps === -1 ? 'AV' : score.p1ps}</th>)
                                                    }
                                                </tr>
                                                <tr>
                                                    {score.playerTwo && (
                                                        <th className="parejas">{score.playerTwo.toUpperCase()}</th>
                                                    )}
                                                    <th className="saque">{score.saque === 2 ? '🟡' : ''}</th>
                                                    {score.p21s >= 0 && score.p21s != null && <th className="set">{score.p21s}</th>}
                                                    {score.p22s >= 0 && score.p22s != null && <th className="set">{score.p22s}</th>}
                                                    {score.p23s >= 0 && score.p23s != null && <th className="set">{score.p23s}</th>}
                                                    {score.p2ps >= -1 && (score.isTieBreak === true ?
                                                        <th className="tbr">{score.p2ps}</th>
                                                        : <th className="puntos">{score.p2ps === -1 ? 'AV' : score.p2ps}</th>)
                                                    }
                                                </tr>
                                                </tbody>
                                            </table>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={12}>
                                <Card>
                                    <Card.Header>Botonera</Card.Header>
                                    <Card.Body>
                                        <div className='buttons-container'>
                                            <div>
                                                <p>Player 1</p>
                                                <input type="text" value={score.playerOne}
                                                       onChange={(e) => handleName(e, 'playerOne')}
                                                       className='name-player'/>
                                            </div>
                                            <div className='set-container'>
                                                <p>Serve</p>
                                                <Button onClick={() => handleServe(1)}>🟡</Button>
                                            </div>
                                            <div className='set-container'>
                                                <p>Games</p>
                                                <Button onClick={() => handleGame(1, 'decrease')}>-</Button>
                                                <Button onClick={() => handleGame(1, 'increase')}>+</Button>
                                            </div>
                                            <div className='set-container'>
                                                <p>Points</p>
                                                <Button onClick={() => handlePoint(1, 'decrease')}>-</Button>
                                                <Button onClick={() => handlePoint(1, 'increase')}>+</Button>
                                            </div>
                                        </div>
                                        <div className='buttons-container'>
                                            <div>
                                                <p>Player 2</p>
                                                <input type="text" value={score.playerTwo}
                                                       onChange={(e) => handleName(e, 'playerTwo')}
                                                       className='name-player'/>
                                            </div>
                                            <div className='set-container'>
                                                <p className='hidden-title'>Serve</p>
                                                <Button onClick={() => handleServe(2)}>🟡</Button>
                                            </div>
                                            <div className='set-container'>
                                                <p className='hidden-title'>Games</p>
                                                <Button onClick={() => handleGame(2, 'decrease')}>-</Button>
                                                <Button onClick={() => handleGame(2, 'increase')}>+</Button>
                                            </div>
                                            <div className='set-container'>
                                                <p className='hidden-title'>Points</p>
                                                <Button onClick={() => handlePoint(2, 'decrease')}>-</Button>
                                                <Button onClick={() => handlePoint(2, 'increase')}>+</Button>
                                            </div>
                                        </div>
                                    </Card.Body>
                                    <div className='buttons-container reset-button'>
                                        <Button onClick={handleReset}>Reset</Button>
                                    </div>
                                </Card>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default TenisGamePage;
