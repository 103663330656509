import { useState, useEffect, useRef } from 'react';

export default function useMercure(initialTopic) {
    const url = process.env.REACT_APP_API_MERCURE;
    const [topic, setTopic] = useState(initialTopic);
    const [data, setData] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const eventSourceRef = useRef(null); // Referencia para manejar la conexión actual

    useEffect(() => {
        if (!topic || topic.includes("undefined")) return; // Si no hay un topic, no establecer conexión

        const fullUrl = new URL(url);
        fullUrl.searchParams.append('topic', `${process.env.REACT_APP_API_URL}/${topic}`);

        // Cierra la conexión existente antes de crear una nueva
        if (eventSourceRef.current) {
            eventSourceRef.current.close();
        }

        const eventSource = new EventSource(fullUrl);
        eventSourceRef.current = eventSource;

        eventSource.onopen = () => setIsConnected(true);
        eventSource.onerror = () => {
            setIsConnected(false);
            eventSource.close(); // Asegúrate de cerrar en caso de error
        };
        eventSource.onmessage = (event) => {
            setData(JSON.parse(event.data));
        };

        // Limpieza al desmontar el componente o cambiar el topic
        return () => {
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
            }
        };
    }, [topic, url]);

    // Método para actualizar el topic
    const updateTopic = (newTopic) => {
        if (newTopic !== topic) {
            setTopic(newTopic); // Cambia el topic y fuerza el reinicio de la conexión
        }
    };

    return { data, isConnected, updateTopic };
}
