import React, {useState} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import {createNewGame} from "../../../api/request/sportRequest";
import {useNavigate} from "react-router-dom";
import {renderCheckboxField, RenderPlayerInput} from "../../common/InputComponent";
import {toast} from "sonner";

const PadelGame = ({ showModal, handleCloseModal, id, userData}) => {
    const navigate = useNavigate();
    const [gameType, setGameType] = useState('individual'); // Estado para almacenar el tipo de juego seleccionado
    const [padelMode, setPadelMode] = useState('oro'); // Estado para almacenar el tipo de juego seleccionado
    const [player1, setPlayer1] = useState(null)
    const [player2, setPlayer2] = useState(null)


    const handleNewGame = async() => {
        if (!player1 || !player2) {
            alert('Por favor, complete los nombres de ambos jugadores.');
            return;
        }

        const formData = new FormData();
        formData.append('userid', userData.id)
        formData.append('player_one', player1);
        formData.append('player_two', player2);
        formData.append('individual', gameType === 'individual');
        formData.append('mode', padelMode);

        try {
            const response = await createNewGame(formData, 'padel');
            if (response.status === 200) {
                toast.success('Partido creado con exito')
                navigate(`/sports/${id}/game/${response.data.id}`);
            }
        } catch (error) {
            toast.error('Error a crear el partido');
            console.error('Error creando el juego:', error);
            alert('Error al crear el juego. Intente nuevamente.');
        }

    }

    const handleGameTypeChange = (event) => {
        setGameType(event.target.value);
    }


    const handlePadelModeChange = (event) => {
        setPadelMode(event.target.value);
    }

    return (
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Nuevo Partido</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label className={'mt-4'}>Tipo de Juego</Form.Label>
                        <Form.Group>
                            {renderCheckboxField('individual', gameType, handleGameTypeChange)}
                            {renderCheckboxField('parejas', gameType, handleGameTypeChange)}
                        </Form.Group>
                    </Form.Group>
                    <RenderPlayerInput
                        gameType={gameType}
                        controlId="player1"
                        playerNumber={1}
                        value={player1}
                        onChange={(event) => setPlayer1(event.target.value)}
                    />
                    <RenderPlayerInput
                        gameType={gameType}
                        controlId="player2"
                        playerNumber={2}
                        value={player2}
                        onChange={(event) => setPlayer2(event.target.value)}
                    />
                     <Form.Group>

                        <Form.Label>Modalidad</Form.Label>
                        <Form.Group>
                            {renderCheckboxField('normal', padelMode, handlePadelModeChange)}
                            {renderCheckboxField('oro', padelMode, handlePadelModeChange)}
                        </Form.Group>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleNewGame}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PadelGame;
