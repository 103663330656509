import api, {asyncRequest, getHeader} from '../api';
import jwt from 'jwt-decode';

export const login = async (formData) => {
    return await asyncRequest( async () => {
        const response = await api.post('/login',formData,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        return response;
    })
}

export const register = async (formData) => {
    return await asyncRequest( async () => {
        const response = await api.post('/users',formData,
            {
                headers: {
                    'Content-Type': 'application/ld+json',
                },
            });
        localStorage.setItem("refreshToken", response.data.refreshToken)
        localStorage.setItem("jwt", response.data.token);
        return response.status;
    });
}


export const fetchUser = async () => {
    return await asyncRequest(async () => {
        const response = await api.get('/get_user',
            {
                headers: getHeader(),
            }
        );
        // console.log(JSON.stringify(response.data))
        // const userIri = response.headers.get('Location');
        localStorage.setItem('userData', JSON.stringify(response.data))
        return response.data;
    });
}

export const fetchStreamUser = async (username) => {
    return await asyncRequest(async () => {
        const response = await api.get(`/get_stream/${username}`,
            {headers: {'Content-Type': 'application/json'}}
        );
        return response.data;
    });
}

export const logout = () => {
    try{
        // const response = axios.post(
        //     'https://localhost/logout', null,{
        //         Authorization: 'Bearer '+ localStorage.getItem('token')
        //     }
        // )

    }catch(error) {
        console.log(error.response.data);
    }
}

export const fetchStreamers = async (username) => {
    return await asyncRequest(async () => {
        const response = await api.get(`/streamers?username=${username}`,
            {headers: {'Content-Type': 'application/ld+json'}}
        );
        return response.data['hydra:member'];
    });
}