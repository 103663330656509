import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import {fetchUser, login, register} from '../../api/request/agentRequest';
import { useAuth } from "../../context/AuthContext";

import '../../styles/login.css';
import {toast} from "sonner";

const Register = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        confirmPassword: '',
        email: ''
    });
    const [error, setError] = useState('');
    const { setLogin } = useAuth();

    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Verificar si las contraseñas coinciden
        if (formData.password !== formData.confirmPassword) {
            setError("Las contraseñas no coinciden.");
            return;
        }

        // Limpiar el error si las contraseñas coinciden
        setError('');

        try {
            const response = await register({
                username: formData.username,
                email: formData.email,
                plainPassword: formData.password
            });

            if (response !== 201) throw new Error('Registro fallido.');

            // Iniciar sesión
            const loginResponse = await login({ username: formData.username, password: formData.password });

            if (!loginResponse) throw new Error('Error al iniciar sesión.');

            setLogin(loginResponse.data);

            // Obtener datos del usuario
            const user = await fetchUser(loginResponse);

            if (user) {
                toast.success('Usuario creado correctamente');
                navigate('/');
            }
        } catch (error) {
            setError("Hubo un error en el registro. Inténtalo nuevamente.");
            toast.error(error);
            console.error(error);
        }
    }

    return (
        <>
            <Helmet>
                <title>Register</title>
            </Helmet>
            <div className="login-bg">
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="login-header text-center">
                        <h2>Registro</h2>
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    <div className="form-group mb-3">
                        <input
                            type="text"
                            className="form-control"
                            name="username"
                            placeholder="Usuario"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Correo Electrónico"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            placeholder="Contraseña"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <input
                            type="password"
                            className="form-control"
                            name="confirmPassword"
                            placeholder="Confirmar Contraseña"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <Button className="login-button" type="submit">
                        Registrarse
                    </Button>
                </form>
            </div>
        </>
    );
}

export default Register;
